import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import parse from 'html-react-parser'

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import '../styles/@wordpress/block-library/build-style/style.css'
import '../styles/@wordpress/block-library/build-style/theme.css'

import Layout from '../components/layout'
import Seo from '../components/seo'

const BlogPost = styled.article`
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  .wp-block-button__link {
    color: #fbfbff !important;
  }

  header {
    position: relative;
    max-width: 816px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 24px 32px;
    margin-bottom: 60px;
    z-index: 1;
    background: #fbfbff;
    box-sizing: border-box;
    transition: all 0.2s;
    border-bottom: 0.5px solid #e2e2e5;

    .top {
      position: relative;
      z-index: 3;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .categories {
        z-index: 3;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        a,
        small {
          z-index: 3;
          margin-right: 12px;
          font-family: 'Lexend Deca', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 13px;
          letter-spacing: 0.005em;
          color: #656565;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .date {
        small {
          transition: all 0.2s;
        }
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h2 {
        margin-bottom: 12px;
        transition: all 0.2s;
      }
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
        color: #656565;
      }
    }
    @media (max-width: 768px) {
      .bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h2 {
          font-size: 32px;
          line-height: 135%;
          margin-bottom: 12px;
          text-align: center;
        }
        p {
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
        }
      }
    }
  }

  .featured-image {
    margin: 16px 0 24px 0;
  }

  .article-body {
    max-width: 816px;
    * {
      font-family: 'Lexend Deca', sans-serif;
      overflow-wrap: break-word;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #2f3439;
      margin-top: 32px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      letter-spacing: 0.007em;
      color: #656565;
    }
    a {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      color: #00adef;
    }
    img {
      width: 100%;
      height: auto;
    }
    @media (max-width: 768px) {
      max-width: 100%;
      padding: 0 12px;
      h1 {
        font-size: 1.6rem;
      }
      h2 {
        font-size: 1.5rem;
      }
      h3 {
        font-size: 1.4rem;
      }
      h4 {
        font-size: 1.3rem;
      }
      h5 {
        font-size: 1.2rem;
      }
      h6 {
        font-size: 1.25rem;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 160%;
      }
      a {
        font-size: 1.2rem;
      }
    }
  }

  .categorias {
    position: relative;
    max-width: 816px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px 24px 32px;
    margin-top: 42px;
    z-index: 1;
    background: #fbfbff;
    box-sizing: border-box;
    transition: all 0.2s;
    border-top: 0.5px solid #e2e2e5;

    .top {
      position: relative;
      z-index: 3;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .categories {
        z-index: 3;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        a,
        small {
          z-index: 3;
          margin-right: 12px;
          font-family: 'Lexend Deca', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 13px;
          letter-spacing: 0.005em;
          color: #656565;
          transition: all 0.2s;
          margin: 24px 0;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            color: #00adef;
            transition: all 0.2s;
          }
        }
      }
      .date {
        small {
          transition: all 0.2s;
        }
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h4 {
        margin-bottom: 4px;
        transition: all 0.2s;
        margin-bottom: 0;
      }
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #656565;
        margin-bottom: 28px;
      }
    }
    @media (max-width: 768px) {
      .top {
        .categories {
          z-index: 3;
          display: grid;
          grid-template-columns: 1fr;
          a {
            margin: 12px 0;
          }
        }
        .date {
          small {
            transition: all 0.2s;
          }
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h4 {
          font-size: 18px;
          line-height: 135%;
          margin-bottom: 12px;
          text-align: center;
        }
        p {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 25px;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 12px;
  }
`

const BlogPostTemplate = (
  { data: { previous, next, post, pathname, allWpMediaItem } },
  location
) => {
  const { categories, date, title, featuredImage, excerpt } = post

  return (
    <Layout location={location} pathname={pathname} isBlogPost>
      <Seo title={post.title} description={post.excerpt} />
      <BlogPost
        className='blog-post'
        itemScope
        itemType='http://schema.org/Article'
      >
        <header>
          <div className='top'>
            <div className='categories'>
              {categories &&
                categories.nodes.slice(0, 3).map(category => {
                  return (
                    <Link to={`/blog?busca=${category.name.replace(' ', '-')}`}>
                      <small>{category.name}</small>
                    </Link>
                  )
                })}
            </div>
            <div className='date'>
              <small>{date}</small>
            </div>
          </div>
          <div className='bottom'>
            <h2>{title}</h2>
            {/* <p>{excerpt.replace(/<p>/g, '').replace(/<\/p>/g, '')}</p> */}
          </div>
        </header>

        {featuredImage ? (
          <div className='featured-image'>
            <img src={featuredImage.node.sourceUrl} alt='' />
          </div>
        ) : null}

        {!!post.content && (
          <section itemProp='articleBody' className='article-body'>
            {parse(post.content)}
          </section>
        )}

        <div className='categorias'>
          <div className='bottom'>
            <h4>Categorias</h4>
            <p>Clique na categoria para ver mais postagens relacionadas</p>
          </div>
          <div className='top'>
            <div className='categories'>
              {categories &&
                categories.nodes.map(category => {
                  return (
                    <Link to={`/blog?busca=${category.name.replace(' ', '-')}`}>
                      <small>{category.name}</small>
                    </Link>
                  )
                })}
            </div>
          </div>
        </div>
      </BlogPost>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      content
      title
      date(formatString: "DD \\d\\e MMMM, YYYY", locale: "pt-BR")
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    allWpMediaItem {
      edges {
        node {
          sourceUrl
        }
      }
    }
  }
`

/* featuredImage {
  node {
    altText
    localFile {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          placeholder: TRACED_SVG
          layout: FULL_WIDTH
        )
      }
    }
  }
} */
